import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";

const SkillCard = ({ skillName, skillIcon, skillDescription }) => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <div className=" ">
      <button
        className="text-gray-700 m-2 w-48 h-48 bg-white flex-none mx-auto text-center p-5 rounded-xl border-2 border-gray-300 ml-1 mr-1 hover:bg-gray-800 hover:text-white transition-colors duration-300 shadow-lg focus:border-gray-300"
        type="button"
        onClick={() => setShowModal(true)}
      >
        {skillIcon}
        <p className="text-md font-semibold mt-4">{skillName}</p>
      </button>
      <Transition
        as={React.Fragment}
        show={showModal}
        enter="transform duration-300 transition ease-in-out"
        enterFrom="opacity-0 scale-75"
        enterTo="opacity-100 rotate-0 scale-100"
        leave="transform duration-300 transition ease-in-out"
        leaveFrom="opacity-100 rotate-0 scale-100 "
        leaveTo="opacity-0 scale-75"
      >
        <div className=" bg-black bg-opacity-25 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto mx-auto max-w-3xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl font-semibold items-center flex space-x-4">
                  {skillIcon} <span>{skillName}</span>
                </h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                <p className="my-4 text-slate-500 text-lg text-black leading-relaxed">
                  <img src={skillDescription} className=""></img>
                </p>
              </div>
              <div className="flex items-center justify-start p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default SkillCard;
