import React, { useEffect } from "react";
import "./App.css";
import About from "./components/About";
import Card from "./components/Card";
import Footer from "./components/Footer";
import Projects from "./components/Projects";
import Navbar from "./components/Navbar";
import data from "./assets/data";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  });
  return (
    <div className="bg-gray-100">
      <div className="bg-gray-100">
        <div
          className="lg:h-screen"
          data-aos="fade-down"
          data-aos-duration="800"
        >
          <Card name={data.name} title={data.title} />
          <About
            title={data.about.title}
            description={data.about.description}
            skills={data.skills}
          />
        </div>
      </div>
    </div>
  );
}
export default App;
