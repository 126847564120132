import React from "react";
import GitHubButton from "react-github-btn";

function Footer({ github }) {
  return (
    <div className="text-center w-full mt-16">
      <p className="text-gray-600 mb-4">All Rights Reserved.</p>
    </div>
  );
}

export default Footer;
