import React, { Component } from "react";

class Navbar extends Component {
  render() {
    return (
      <nav class="w-screen z-10 bg-white fixed shadow-lg">
        <div class="container p-6 mx-auto ">
          <div class="flex items-center justify-center gap-4 text-gray-600 capitalize dark:text-gray-300">
            <a
              href=""
              class="border-b-2 border-transparent hover:text-gray-800 dark:hover:text-gray-200 hover:border-blue-500 mx-1.5 sm:mx-6"
            >
              Mon projet professionnel
            </a>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
