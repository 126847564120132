import React from "react";
import {
  FaRoute,
  FaSuitcase,
  FaGraduationCap,
  FaBandAid,
  FaList,
} from "react-icons/fa";

const BOOTSTRAP_FOR_SKILL_ICON = "text-4xl mx-auto inline-block";
const data = {
  name: "Mathilde Pinchon",
  title: "Mon projet professionnel",
  about: {
    title: "Quel serait mon projet idéal ?",
    description:
      "Je vais vous présenter mon projet professionnel en différents points à travers mon site web ! 😁",
  },
  skills: [
    {
      skillName: "Toutes mes formations",
      skillIcon: <FaGraduationCap className={BOOTSTRAP_FOR_SKILL_ICON} />,
      skillDescription: require("../images/formations.png"),
    },
    {
      skillName: "Expériences professionnelles",
      skillIcon: <FaSuitcase className={BOOTSTRAP_FOR_SKILL_ICON} />,
      skillDescription: require("../images/experiencepro.png"),
    },
    {
      skillName: "Secteur d'activité et poste",
      skillIcon: <FaBandAid className={BOOTSTRAP_FOR_SKILL_ICON} />,
      skillDescription: require("../images/secteur.png"),
    },
    {
      skillName: "Mes missions principales",
      skillIcon: <FaList className={BOOTSTRAP_FOR_SKILL_ICON} />,
      skillDescription: require("../images/mission.png"),
    },
    {
      skillName: "Posture primordiale",
      skillIcon: <FaRoute className={BOOTSTRAP_FOR_SKILL_ICON} />,
      skillDescription: require("../images/posture.png"),
    },
  ],
};
export default data;
