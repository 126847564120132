import React from "react";

import Skills from "./Skills";

function About({ title, description, skills }) {
  return (
    <div className=" w-screen mx-auto mt-16">
      <p className="text-2xl md:text-4xl font-bold text-center text-gray-800">
        {title}
      </p>
      <p className="text-base text-center md:text-center text-gray-600 leading-relaxed mt-4 ml-2 mt-2">
        {description}
      </p>
      <Skills skills={skills} />
    </div>
  );
}

export default About;
